import {useSpring, animated, config} from 'react-spring';

const AnimatedNumber = ({value, from, className}) => {

  const {number} = useSpring({
    reset: true,
    from: {number: from},
    number: value,
    delay: 200,
    config: config.molasses,
  });

  return <animated.div className={className}>{number.to(
      n => parseInt(n))}</animated.div>;

};

export default AnimatedNumber;