import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import Link from 'next/link';

export default function HomeIncentives() {

  const {t: translateCommon} = useTranslation('common');
  const {t: translatePages} = useTranslation('pages');

  const incentives = [
    {
      name: translatePages('HOME.INCENTIVES.POINT_1_TITLE'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/41d1be90cc8e56f3c5de98847a0aae5f.png',
      description: translatePages('HOME.INCENTIVES.POINT_1_DESCRIPTION'),
    },
    {
      name: translatePages('HOME.INCENTIVES.POINT_2_TITLE'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/e7d4976b3b4ce03d271b6ac7c015922c.png',
      description: translatePages('HOME.SELLING_POINT_CONTENT_3'),
    },
    {
      name: translatePages('HOME.INCENTIVES.POINT_3_TITLE'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/19ee492853b1e963db6a9b80d659c768.png',
      description: <><span> {translatePages(
          'HOME.INCENTIVES.POINT_3_DESCRIPTION_PRE')} </span>
        <Link href={`/support`}><a
            className={'font-semibold text-blue-600 hover:underline'}> {translatePages(
            'HOME.INCENTIVES.POINT_3_DESCRIPTION_SUPPORT')}</a></Link>
        <span> {translatePages(
            'HOME.INCENTIVES.POINT_3_DESCRIPTION_POST')} </span></>,

    },
  ];

  return (
      <div className="bg-white">
        <div
            className="max-w-7xl mx-auto pt-0 sm:pt-0 pb-24 sm:px-2 sm:pb-32 lg:px-4">
          <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
            <div
                className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
              <div>
                <h2 className="text-4xl font-extrabold text-gray-900">
                  {translatePages('HOME.INCENTIVES.TITLE')}
                </h2>
                <p className="mt-4 text-gray-500">
                  {translatePages('HOME.INCENTIVES.DESCRIPTION')}
                </p>
              </div>
              <div
                  className="aspect-w-3 aspect-h-2 bg-gray-100 rounded-lg overflow-hidden relative h-80 shadow-2xl">
                <Image
                    src={'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/c1fa4edcd1b0cd237d0af8ed0b2d42fa.png'}
                    className={''}
                    alt={'AMPTech Ranger Service'}
                    layout={'fill'}
                    objectFit={'cover'}
                    loading={'lazy'}
                    quality={100}/>
              </div>
            </div>
            <div
                className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              {incentives.map((incentive) => (
                  <div key={incentive.name} className="sm:flex lg:block">
                    <div className="sm:flex-shrink-0">
                      <div className="w-16 h-16 relative">
                        <Image
                            src={incentive.imageSrc}
                            className={''}
                            alt={incentive.name}
                            layout={'fill'}
                            loading={'lazy'}
                            objectFit={'cover'}
                            quality={100}/>
                      </div>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                      <h3 className="text-sm font-medium text-gray-900">{incentive.name}</h3>
                      <p className="mt-2 text-sm text-gray-500">{incentive.description}</p>
                    </div>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
}
