import NewsletterForm from './forms/NewsletterForm';
import React from 'react';

const NewsletterCTA = ({}) => {
  return <div className="relative sm:py-4">
    <div aria-hidden="true" className="hidden sm:block">
      <div
          className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"/>
      <svg className="absolute top-8 left-1/2 -ml-3" width={404} height={392}
           fill="none" viewBox="0 0 404 392">
        <defs>
          <pattern
              id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
          >
            <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                  fill="currentColor"/>
          </pattern>
        </defs>
        <rect width={404} height={392}
              fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"/>
      </svg>
    </div>
    <div
        className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <NewsletterForm showCloseBtn={false} className={`rounded-2xl`} useH1={false}/>
    </div>
  </div>;
};

export default NewsletterCTA;
