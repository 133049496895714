import React from 'react';
import {useTranslation} from 'next-i18next';

const HomeHero = ({}) => {

  const {t: translateCommon} = useTranslation('common');

  return <section>
    <div
        className={'w-full flex flex-col h-80 bg-gray-900'}
        style={{
          backgroundImage: `url(https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/550ef8dd0387d926e8806dc4716b94a5.png)`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
    >
      <div className={'mx-auto my-auto '}>
        <center><h1
            className={'font-serif uppercase text-xl md:text-5xl tracking-wider font-bold text-white  '}>
          {translateCommon('BRAND_SLOGAN_TITLE')}
        </h1></center>
      </div>
      <p className={'text-center text-white uppercase text-tiny font-title tracking-widest pb-3'}>
        {translateCommon('BRAND_SLOGAN_SUBTITLE')}
      </p>
    </div>
  </section>;
};

export default HomeHero;
