import React from 'react';
import {useTranslation} from 'next-i18next';
import {categoryRoutes} from '../../../lib/routes';
import {useRouter} from 'next/router';
import Link from 'next/link';

const HomeStickyCategoryMenu = ({}) => {

  const {t: translateMenu} = useTranslation('menu');
  const router = useRouter();

  const listItems = categoryRoutes(translateMenu, router).map((link, index) =>
      <Link key={`home-category-menu-${index}`} href={link.uri}>
        <a className={'bg-white bg-opacity-40 hover:bg-opacity-80 backdrop-filter backdrop-blur-lg uppercase text-base p-1 rounded transition'}>
          <span
              className={'px-3 text-xs md:text-base md:tracking-wide'}>{link.title} </span>
        </a>
      </Link>,
  );
  return <>
    <div
        className={'sticky top-11 z-30 bg-gradient-to-r from-red-900 via-red-600 to-red-900 w-full text-white uppercase text-center tracking-wide py-1.5'}>
      {translateMenu('CHOOSE_YOUR_MODEL')}
    </div>
    <nav
        className={'bg-gradient-to-r sticky z-20 top-20 from-yellow-900  via-yellow-500 to-yellow-900 w-full uppercase py-1.5 z-30'}>
      <ul className={'flex py-2 flex-row justify-center text-black duration-1000 transition gap-1 md:gap-3 lg:gap-5'}>
        {listItems}
      </ul>
    </nav>
  </>;
};

const HomeStickyCategoryMenu2 = ({}) => {

  const {t: translateMenu} = useTranslation('menu');
  const router = useRouter();

  return <>
    <div
        className={'bg-gradient-to-r from-blue-500 to-indigo-700'}>
      <h2 className={'text-center uppercase text-indigo-100 font-title text-2xl py-2 sm:py-4'}>{translateMenu(
        'CHOOSE_YOUR_MODEL')} </h2></div>
    <div className={'w-full bg-gray-100 sticky top-10 z-30'}>
      <div
          className={'max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8'}>
        {/*<div*/}
        {/*    className={'sticky top-11 z-30 w-full uppercase tracking-wide py-1.5'}>*/}
        {/*  {translateMenu('CHOOSE_YOUR_MODEL')}*/}
        {/*</div>*/}
        <nav
            className={'uppercase'}>
          <ul className={'flex  px-4 py-2 sm:py-4 flex-row divide-solid divide-x justify-center gap-1 md:gap-3 lg:gap-5'}>
            {categoryRoutes(translateMenu, router).map((link, index) =>
                    <Link key={`home-category-menu-${index}`} href={link.uri}>
                      <a className={'uppercase text-base p-1 rounded transition pl-1 md:pl-3 lg:pl-5 text-gray-800 hover:text-gray-500'}>
          <span
              className={'px-3 text-xs md:text-base md:tracking-wide'}>{link.title} </span>
                      </a>
                    </Link>,
            )}
          </ul>
        </nav>
      </div>
    </div>
  </>;
};

export default HomeStickyCategoryMenu;
