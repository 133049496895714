import {useRef, useState} from 'react';

export const useTailwindUiModal = () => {

  const [open, setOpen] = useState();
  const modalRef = useRef();

  return [open, setOpen, modalRef];

};
