import React from 'react';

const CustomPrevArrowButton = ({onClickPrev, className = ''}) => {

  return <div onClick={onClickPrev}
              className={'bg-gray-200 hover:bg-gray-700 p-2 rounded-full h-8 w-8 hover:text-gray-200 text-gray-800  z-10 transition absolute left-3 top-1/2 cursor-pointer ' +
              className}>
    <svg
        className={'w-auto h-auto'}
        fill="none" stroke="currentColor"
        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
            d="M15 19l-7-7 7-7"/>
    </svg>
  </div>;

};

export default CustomPrevArrowButton;
