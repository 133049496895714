import Image from 'next/image';
import React, {useEffect, useState} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import CustomNextArrowButton from '../../CustomNextArrowButton';
import CustomPrevArrowButton from '../../CustomPrevArrowButton';
import {ChevronRightIcon} from '@heroicons/react/outline';
import Link from 'next/link';

export default function HomePartnerLogos({partners}) {
  const [currentIndex, setCurrentIndex] = useState(3);

  const [emblaRef, emblaApi] = useEmblaCarousel(
      {
        loop: true,
        startIndex: currentIndex,
        draggable: false,

      });

  useEffect(() => {
    if (emblaApi) {
    }
  }, [emblaApi]);

  const onClickNext = () => {

    const newIndex = currentIndex === partners.length - 1 ?
        0 :
        currentIndex + 1;

    if (emblaApi && emblaApi.canScrollNext()) {
      setCurrentIndex(newIndex);
      emblaApi.scrollNext();
    }

  };

  const onClickPrev = () => {

    const newIndex = currentIndex === 0 ?
        partners.length - 1 :
        currentIndex - 1;

    if (emblaApi && emblaApi.canScrollPrev()) {
      setCurrentIndex(newIndex);
      emblaApi.scrollPrev();
    }

  };

  return (
      <div className="bg-white relative">
        <CustomNextArrowButton className={''} onClickNext={onClickNext}/>
        <CustomPrevArrowButton className={''} onClickPrev={onClickPrev}/>

        <div
            className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 overflow-x-hidden w-full"
            ref={emblaRef}>


          <div className="gap-8 flex flex-row">
            {partners.map(partner => {

              const {logo, websiteUrl} = partner;
              const {alternativeText, url} = logo;

              const logoUrl = !!!logo.formats ? url :
                  !!logo.formats.thumbnail ?
                      logo.formats.thumbnail.url :
                      !!logo.formats.small.url ?
                          logo.formats.small.url :
                          !!logo.formats.medium.url ?
                              logo.formats.medium : url;

              return <div
                  key={`logo-to-site-${websiteUrl}`}
                  className="h-12 w-52 relative" style={{flex: '0 0 150px'}}>
                <Link href={websiteUrl} passHref={true}>
                  <a target="_blank" rel="noopener noreferrer">
                    <Image
                        src={logoUrl}
                        alt={alternativeText}
                        layout={'fill'}
                        className={'grayscale hover:grayscale-0 transition-all '}
                        objectFit={'contain'}
                        loading={'lazy'}
                        quality={100}/>
                  </a>
                </Link>
              </div>;
            })}


          </div>
        </div>
      </div>
  );
}
