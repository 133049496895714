/* This example requires Tailwind CSS v2.0+ */
import useEmblaCarousel from 'embla-carousel-react';
import {useEffect, useState} from 'react';
import CustomNextArrowButton from '../../CustomNextArrowButton';
import CustomPrevArrowButton from '../../CustomPrevArrowButton';
import StarReview from '../../productOverview/starReview';
import {useTranslation} from 'next-i18next';

export default function HomeTestimonial({reviewsToShowcase}) {

  const [focusedIndex, setFocusedIndex] = useState(1);

  const [emblaRef, emblaApi] = useEmblaCarousel(
      {
        startIndex: focusedIndex,
        align: 'center',
        skipSnaps: false,
        loop: true,
        draggable: false,

      });

  useEffect(() => {
    if (emblaApi) {
    }
  }, [emblaApi]);

  const onClickNext = () => {

    const newIndex = focusedIndex === reviewsToShowcase.length - 1 ?
        0 :
        focusedIndex + 1;

    if (emblaApi && emblaApi.canScrollNext()) {
      setFocusedIndex(newIndex);
      emblaApi.scrollNext();
    }

  };

  const onClickPrev = () => {

    const newIndex = focusedIndex === 0 ?
        reviewsToShowcase.length - 1 :
        focusedIndex - 1;

    if (emblaApi && emblaApi.canScrollPrev()) {
      setFocusedIndex(newIndex);
      emblaApi.scrollPrev();
    }

  };

  return (
      <section className="py-12 bg-gray-50 md:py-20 lg:py-8 relative">
        <div
            ref={emblaRef}
            className="overflow-x-hidden w-full px-4 sm:px-6 lg:px-8 min-h-16rem">
          <div className={'flex'}>
            {reviewsToShowcase.map(
                (review, index) => <TestimonialContainer
                    key={`testimonial-${index}-mobile`}
                    review={review}
                    focusedIndex={focusedIndex}
                    setFocusedIndex={setFocusedIndex}
                    index={index}
                    containerStyle={{flex: '0 0 100%'}}
                    className={'my-auto'}
                />,
            )
            }
          </div>

          <svg
              className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
              width={404}
              height={404}
              fill="none"
              viewBox="0 0 404 404"
              role="img"
              aria-labelledby="svg-workcation"
          >
            <defs>
              <pattern
                  id="ad119f34-7694-4c31-947f-5c9d249b21f3"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                      fill="currentColor"/>
              </pattern>
            </defs>
            <rect width={404} height={404}
                  fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"/>
          </svg>

          <CustomNextArrowButton className={''} onClickNext={onClickNext}/>

          <CustomPrevArrowButton className={''} onClickPrev={onClickPrev}/>
        </div>
      </section>
  );
}

const TestimonialContainer = ({
                                review,
                                index,
                                focusedIndex,
                                setFocusedIndex,
                                containerStyle = {flex: '0 0 33%'},
                                className,
                              }) => {

  const {t: translateCommon} = useTranslation('common');

  const currentFocused = focusedIndex === index;

  const {
    authorName,
    id,
    macroProduct,
    review: reviewText,
    stars,
    variationProduct,
  } = review;

  const product = !!macroProduct ?
      macroProduct :
      !!variationProduct ? variationProduct : null;

  if (!product)
    return null;

  const {id: productId, shortTitle, slug, title, __typename} = product;

  const category = __typename.toLowerCase().includes('macro') ?
      product.category.title :
      'JOWUA';

  return <div
      style={containerStyle}
      className={`${className} relative transition-all transform ${currentFocused ?
          'opacity-100 scale-100' :
          'opacity-50 scale-75'}`}>

    <blockquote className={`${currentFocused ? '' : 'text-sm'}`}>
      <footer className="mb-8">
        <div className="md:flex md:items-center md:justify-center">
          <div
              className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
            <div className="text-base font-medium text-gray-900">
              {authorName}
            </div>

            <svg
                className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                fill="currentColor" viewBox="0 0 20 20">
              <path d="M11 0h3L9 20H6l5-20z"/>
            </svg>

            <>
              <div
                  className="text-base font-medium text-gray-500">
                {category} <span>{shortTitle}</span>

              </div>
            </>

          </div>

        </div>
      </footer>
      <div
          className=" max-w-md lg:max-w-3xl mx-auto text-center text-xl lg:text-2xl leading-9 font-medium text-gray-900">
        <p>
          &ldquo;{reviewText}&rdquo;
        </p>
      </div>
      <div className={'flex flex-row justify-center items-center mt-8'}>
        <h3 className={'text-sm text-gray-700 mr-2'}>{translateCommon(
            'BTN.REVIEW')}: {' '} </h3>
        <StarReview review={review} className={''}
                    starColor={'text-amber-500'}/>
      </div>

    </blockquote>
  </div>;

};
