import React, {useEffect, useState} from 'react';
import client from '../apollo-client';
import {
    GET_ALL_MACRO_PRODUCTS,
    GET_HOME_STATIC,
} from '../lib/api/graphql/query';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {useTranslation} from 'next-i18next';

import _ from 'lodash';
import MetaHead from '../components/meta/MetaHead';
import {getStrapiLocale} from '../lib/utils/locationUtils';
import qs from 'qs';
import {findAsync} from '../lib/api/rest/strapi';
import {paths} from '../lib/api/rest/paths';
import HomeHero from '../components/pages/home/HomeHero';
import HomeStickyCategoryMenu
    from '../components/pages/home/HomeStickyCategoryMenu';
import HomeAccessoriesProducts
    from '../components/pages/home/HomeAccessoriesProducts';
import HomeEquipmentProducts
    from '../components/pages/home/HomeEquipmentProducts';
import HomeIncentives from '../components/pages/home/HomeIncentives';
import HomeTestimonial from '../components/pages/home/HomeTestimonial';
import NewsletterCTA from '../components/NewsletterCTA';
import HomePartners from '../components/pages/home/HomePartners';
import HomeProduction from '../components/pages/home/HomeProduction';
import HomePartnerLogos from '../components/pages/home/HomePartnerLogos';
import HomeIncentivesUs from '../components/pages/home/HomeIncentivesUs';
import {ClientSideComponent} from "../components/ClientSideComponent";

export default function Home({
                                 macroProducts,
                                 variations,
                                 SEO,
                                 reviewsToShowcase,
                                 partnersToDisplay,
                                 incentivesImage,
                             }) {

    const {t: translatePages} = useTranslation('pages');
    const {t: translateSiteMeta} = useTranslation('site-meta');
    const {t: translateCommon} = useTranslation('common');
    const {t: translateMenu} = useTranslation('menu');

    return (<div className="flex flex-col items-center min-h-screen">

        <MetaHead title={translateSiteMeta('HEADER_TITLE_HOME')}
                  description={!!SEO ? SEO.description : ''}
                  ogCustomImageUrl={!!SEO && !!SEO.thumbnail ?
                      SEO.thumbnail.url :
                      ''}
        />

        <div className="flex flex-col w-full flex-1 ">
            <HomeHero/>
            {/*<HomeHero2/>*/}
            <HomeStickyCategoryMenu/>
            <HomeEquipmentProducts products={macroProducts}/>
            <HomeTestimonial reviewsToShowcase={reviewsToShowcase}/>
            <HomeAccessoriesProducts products={variations}/>
            <ClientSideComponent>
                <HomePartners/>
            </ClientSideComponent>
            <HomePartnerLogos partners={partnersToDisplay}/>
            <HomeIncentives/>
            <NewsletterCTA/>
            <HomeIncentivesUs/>
            <HomeProduction/>
        </div>

    </div>);
}

export async function getStaticProps(context) {
    const {locale, defaultLocale} = context;

    const _locale = getStrapiLocale(locale);

    const {data: macroProductsData} = await client.query({
        query: GET_ALL_MACRO_PRODUCTS, variables: {locale: _locale},
    });

    const {
        data: {
            home: {
                variations, SEO, reviewsToShowcase, partnersToDisplay, incentivesImage,
            },
        },
    } = await client.query({
        query: GET_HOME_STATIC, variables: {locale: _locale},
    });

    const macroProducts = _.groupBy(macroProductsData.macroProducts,
        'shortTitle');

    // Sanitizing
    Object.keys(macroProducts).map(productKey => {
        const macroProductsArr = macroProducts[productKey];
        for (let product of macroProductsArr) {
            if (!!!product['category']) delete macroProducts[productKey];
        }
    });

    return {
        props: {
            macroProducts,
            locale,
            variations,
            reviewsToShowcase,
            partnersToDisplay,
            incentivesImage,
            SEO, ...(await serverSideTranslations(locale,
                ['common', 'pages', 'products', 'site-meta', 'menu'])),
        },
    };
}
