import AMPTechLogoSvg from '../../svg/amptechLogoSvg';
import React from 'react';
import {useTranslation} from 'next-i18next';

const HomeProduction = ({}) => {

  const {t: translatePages} = useTranslation('pages');

  return <section className="text-gray-600 body-font">
    <div className="container px-5 py-16 mx-auto">
      <div className="flex flex-col text-center w-full mb-12">
        <center className={'w-full md:w-1/3 lg:self-center mb-5'}>
          <AMPTechLogoSvg type={'big'}/>
          {/*<Image src={logoNoSlogan} alt={'AMPTech'} height={114}*/}
          {/*       width={1155}*/}
          {/*       layout={'responsive'}/>*/}
        </center>
        <h2 className="sm:text-xl text-lg font-medium title-font mb-4 text-gray-900 uppercase tracking-wide">
          {translatePages('HOME.INTERNATIONAL_SECTION_TITLE_1')}
        </h2>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
          {translatePages('HOME.INTERNATIONAL_SECTION_CONTENT_1')}

        </p>
        <h2 className="sm:text-xl text-lg font-medium title-font my-4 text-gray-900 uppercase tracking-wide">
          {translatePages('HOME.INTERNATIONAL_SECTION_TITLE_2')}
        </h2>
        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
          {translatePages('HOME.INTERNATIONAL_SECTION_CONTENT_2')}
        </p>
      </div>

    </div>
  </section>;
};

export default HomeProduction;
