import React, {useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import Image from 'next/image';
import {useTailwindUiModal} from '../../../lib/hooks/useTailwindUiModal';
import TailwindUiModal from '../../layout/TailwindUiModal';
import {ShieldCheckIcon} from '@heroicons/react/outline';
import Link from 'next/link';
import {getMacroProductRoute} from '../../../lib/utils/productUtils';

const HomeEquipmentProducts = ({products}) => {

  const {t: translatePages} = useTranslation('pages');
  const {t: translateCommon} = useTranslation('common');
  const {t: translateMenu} = useTranslation('menu');

  const [modalIsOpen, setModalIsOpen, modalRef] = useTailwindUiModal();
  const [selectedThumbnailPicture, setSelectedThumbnailPicture] = useState(
      null);

  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);

  const {locale} = useRouter();

  const handleOpenModal = (e, title, products) => {

    e.preventDefault();
    setSelectedTitle(title);
    const [first] = products;
    const {wireframe} = first.media;

    setSelectedProducts(products);
    setSelectedThumbnailPicture(wireframe);

    setModalIsOpen(true);

  };

  return <div className="bg-white">
    <div
        className="max-w-2xl mx-auto pt-8 sm:pt-12 pb-16 px-4 sm:pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <TailwindUiModal setOpen={setModalIsOpen} open={modalIsOpen}
                       reference={modalRef}
                       maxWidth={'max-w-3xl'}
                       onClose={() => setModalIsOpen(false)}>
        {!!selectedProducts && !!selectedThumbnailPicture && <div
            className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:gap-x-8">
          <div className="sm:col-span-4 lg:col-span-5">
            <div
                className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden relative h-60">
              <Image
                  src={selectedThumbnailPicture.url}
                  className={''}
                  alt={selectedThumbnailPicture.alternativeText}
                  layout={'fill'}
                  objectFit={'scale-down'}
                  quality={100}/>

            </div>

          </div>
          <div className="sm:col-span-8 lg:col-span-7">
            <h2 className="font-title uppercase tracking-wide text-2xl text-red-800 sm:pr-12">{selectedTitle}</h2>

            <section aria-labelledby="information-heading" className="mt-4">
              <h3 id="information-heading" className="sr-only">
                Product information
              </h3>

              <p className={'text-gray-700 mb-4'}> {translateMenu(
                  'CHOOSE_YOUR_MODEL')} </p>

              <div
                  className={'grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5'}>
                {selectedProducts.map(product => {
                  const {category, slug, id} = product;
                  const macroProductRoute = getMacroProductRoute(product,
                      locale, category);
                  return <Link
                      key={`product-${id}`}
                      href={macroProductRoute}>
                    <a className={'w-full py-4 text-center border-gray-400 text-gray-600 hover:border-blue-600 hover:text-blue-800 rounded-md border px-5'}>
                      {category.title}
                    </a>
                  </Link>;

                })}
              </div>
              <div className="mt-6 text-center">
                <p className="group inline-flex text-base font-medium">
                  <ShieldCheckIcon
                      className="flex-shrink-0 mr-2 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                  />
                  <span
                      className="text-gray-500 group-hover:text-gray-700">{translateCommon(
                      '4_YEARS_WARRANTY')}*</span>
                </p>
              </div>

              <p className={'text-tiny mt-3 text-gray-700'}>*{translatePages(
                  'HOME.WORKSHOP_CONTENT')}</p>

            </section>

            <section aria-labelledby="options-heading" className="mt-6">
              <h3 id="options-heading" className="sr-only">
                Product options
              </h3>


            </section>
          </div>
        </div>}
      </TailwindUiModal>
      <h2 className="sm:w-full text-4xl font-extrabold text-gray-900 mb-2 sm:mb-0 text-center">
        {translatePages('HOME.SPECIALIZED_ON_TESLA_TITLE')}
      </h2>
      <p className={'text-gray-700 max-w-xl text-center mx-auto'}>  {translatePages(
          'HOME.SPECIALIZED_ON_TESLA_DESCRIPTION')}</p>
      <div
          className="mt-8 sm:mt-12 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
        {Object.entries(products).map(([title, products]) => {

          const [firstProduct] = products;
          const {media: mainMedia, shortTitle, id} = firstProduct;
          const {formats} = mainMedia.wireframe;
          const categoryString = products.map(
              ({category}) => category.title.split(' ')[1]);

          const image = !!formats.small ?
              formats.small :
              mainMedia.wireframe;
          return <div key={id}
                      onClick={(e) => handleOpenModal(e, title, products)}
                      className="group cursor-pointer">

            <div
                className={'w-full min-h-80 bg-gray-100 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 h-60 lg:h-80 lg:aspect-none relative'}>
              <Image
                  src={image.url}
                  className={''}
                  alt={mainMedia.wireframe.alternativeText}
                  layout={'fill'}
                  objectFit={'scale-down'}
                  quality={100}/>
              <div className="flex items-end z-10 p-4 cursor-pointer">
                <span
                    className="relative text-center w-full bg-white bg-opacity-90 py-2 px-4 rounded-md text-sm text-gray-900 opacity-0 group-hover:opacity-100 focus:opacity-100"
                > {translateCommon('BTN.TO_THE_PRODUCT')}
                </span>
              </div>

            </div>

            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-900 font-semibold">
                  <p aria-hidden="true" className=""/>
                  Tesla Model {categoryString.join('/')} <span
                    className={'text-gray-700 font-medium'}> {shortTitle}</span>
                  {/*</a>*/}
                </h3>
              </div>
            </div>
          </div>;
        })}
      </div>
    </div>
  </div>;
};

export default HomeEquipmentProducts;
