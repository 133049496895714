import React from 'react';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {getAvailableLocals} from '../../lib/utils/generalUtils';

const MetaHead = ({
                    title,
                    description,
                    productJsonLd,
                    ogCustomImageUrl,
                    breadcrumbsJsonLd,
                    faqJsonLd,
                    videoJsonLd,
                    alternativeUrls,
                    ogType = 'website',
                  }) => {

  const {asPath, locale} = useRouter();
  const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;
  const _asPath = asPath.length === 1 ? '' : asPath;
  const url = `${SITE_URL}/${locale}${_asPath}`;
  const defaultImgUrl = `${SITE_URL}/logo/logo_dark.png`;
  const availableLocales = getAvailableLocals();
  const _alternativeUrls = !!alternativeUrls ?
      alternativeUrls :
      availableLocales.map((locale) => ({
        hrefLang: locale, href: `${SITE_URL}/${locale}${_asPath}`,
      }));

  return <Head>
    <title> {title} </title>

    {/*Primary Meta Tags */}
    <meta name="title"
          content={title}/>
    {!!description && <meta name="description"
                            content={description}/>}
    {/*Open Graph / Facebook*/}
    <meta property="og:type" content={ogType}/>
    <meta property="og:url" content={url}/>
    <meta property="og:title"
          content={title}/>
    {!!description && <meta property="og:description"
                            content={description}/>}
    <meta property="og:image"
          content={!!ogCustomImageUrl ? ogCustomImageUrl : defaultImgUrl}/>

    {/*Twitter*/}
    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:url" content={url}/>
    <meta property="twitter:title"
          content={title}/>
    {!!description && <meta property="twitter:description"
                            content={description}/>}
    <meta property="twitter:image"
          content={!!ogCustomImageUrl ? ogCustomImageUrl : defaultImgUrl}/>


    <link rel="canonical" href={url}/>
    {!!_alternativeUrls && _alternativeUrls.map(({hrefLang, href}, index) => (
        <link key={`alternate-url-${index}`} rel="alternate" hrefLang={hrefLang}
              href={href}/>))}

    {!!productJsonLd && <script type={'application/ld+json'}
                                dangerouslySetInnerHTML={{
                                  __html: JSON.stringify(productJsonLd),
                                }}
    />}
    {!!breadcrumbsJsonLd && <script type={'application/ld+json'}
                                    dangerouslySetInnerHTML={{
                                      __html: JSON.stringify(breadcrumbsJsonLd),
                                    }}
    />}
    {!!faqJsonLd && <script type={'application/ld+json'}
                            dangerouslySetInnerHTML={{
                              __html: JSON.stringify(faqJsonLd),
                            }}
    />}
    {!!videoJsonLd && <script type={'application/ld+json'}
                              dangerouslySetInnerHTML={{
                                __html: JSON.stringify(videoJsonLd),
                              }}
    />}
    <link rel="icon"
          href="/favicon.ico"/>
  </Head>;

};

export default MetaHead;
