import React from 'react';
import {useTranslation} from 'next-i18next';
import {
  findLowestProductPrice,
  getJowuaProductRoute,
} from '../../../lib/utils/productUtils';
import {parseToPriceString} from '../../../lib/utils/priceUtils';
import {useRouter} from 'next/router';
import Image from 'next/image';
import Link from 'next/link';

const HomeAccessoriesProducts = ({products: variations}) => {

  const {t: translatePages} = useTranslation('pages');
  const {t: translateSiteMeta} = useTranslation('site-meta');
  const {t: translateCommon} = useTranslation('common');

  const {locale} = useRouter();

  const lowestUpsellPrices = findLowestProductPrice(variations, 'variation');

  return <div className="bg-white">
    <div
        className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 className="sm:w-full text-gray-900 text-4xl font-extrabold text-gray-900 mb-2 sm:mb-0 text-center">
        {translatePages(
            'HOME.TESLA_ACCESSORIES')}
      </h2>
      <p className={'text-gray-700 max-w-xl text-center mx-auto'}>{translatePages(
          'HOME.TESLA_ACCESSORIES_DESCRIPTION')} </p>

      <div
          className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
        {variations.map((product, index) => {

              const {id, slug, simpleProductInformation, shortTitle} = product;
              const {mainImage} = simpleProductInformation;
              const {small} = mainImage.formats;
              const productUrl = getJowuaProductRoute(product, locale);

              return <div key={product.id}
                          className="group relative cursor-pointer">
                <Link href={productUrl}>
                  <a>
                    <div
                        className={'w-full min-h-80 bg-gray-100 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 h-60 lg:h-80 lg:aspect-none relative'}>
                      <Image
                          src={small.url}
                          className={''}
                          alt={small.alternativeText}
                          layout={'fill'}
                          objectFit={'scale-down'}
                          loading={'lazy'}
                          quality={100}/>
                    </div>
                    <div className="mt-4 flex justify-between">
                      <div>
                        <h3 className="text-sm text-gray-700">
                          <span aria-hidden="true" className=""/>
                          {shortTitle}
                        </h3>
                        {/*<p className="mt-1 text-sm text-gray-500">{product.color}</p>*/}
                      </div>
                      <p className="text-sm font-medium text-gray-900">{parseToPriceString(
                          lowestUpsellPrices[index])}</p>
                    </div>
                  </a>
                </Link>

              </div>;
            },
        )}
      </div>
      <div className={'mt-5 flex flex-row justify-center'}>

        <Link href={`/jowua-${locale}`}>
          <a className={`border-gray-300
          transition duration-500 ease-in-out
          rounded-md
          filter
          text-sm
          px-5
          py-2
          border-2 `}>
            {translatePages(
                'HOME.TO_JOWUA_PRODUCTS')}
          </a>
        </Link>

      </div>
    </div>
  </div>;
};

export default HomeAccessoriesProducts;
