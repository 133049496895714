import PartnerMap from '../../partners/partnerMap';
import React, {useEffect, useRef, useState} from 'react';
import Link from 'next/link';
import AnimatedNumber from '../../animations/animatedNumber';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {groupPartnerWorkshopsInCountries} from '../../../lib/utils/locationUtils';
import qs from "qs";
import {findAsync} from "../../../lib/api/rest/strapi";
import {paths} from "../../../lib/api/rest/paths";
import useOnScreen from "../../../lib/hooks/useOnScreen";

export default function HomePartners() {
    const {t: translatePages} = useTranslation('pages');
    const {t: translateCommon} = useTranslation('common');
    const ref = useRef(null);

    const {locale} = useRouter();
    const isVisible = useOnScreen(ref);

    const [partnerWorkshops, setPartnerWorkshops] = useState([]);
    const [hasQueried, setHasQueried] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [numOfCountries, setNumOfCountries] = useState(0)


    useEffect(() => {

        if (isVisible && !hasQueried && !isLoading) {

            const _query = {
                isPublic: true, _limit: 50,
            };

            setIsLoading(true)

            const query = qs.stringify(_query);

            findAsync(paths.PARTNER_WORKSHOPS, false, query).then(response => {

                setPartnerWorkshops(response);
                const groupedPartners = groupPartnerWorkshopsInCountries(response);
                const numOfCountries = Object.keys(groupedPartners).length;
                setNumOfCountries(numOfCountries)

                setHasQueried(true)

            }).catch(err => {
                console.error('Couldn\'t get amp workshops');
            }).finally(() => {
                setIsLoading(false)
            });
        }


    }, [isVisible]);

    const googleMapOptions = {
        center: {
            lat: 50.712018447696494,
            lng: 10.300310765622697,
        },
        zoom: 5,
    };

    return (
        <div className="border-t border-b border-gray-200" ref={ref}>
            <div
                className="grid grid-cols-1 md:grid-cols-2 gap-10 bg-gray-50 rounded-2xl md:pr-10">
                <PartnerMap options={googleMapOptions}
                            showSearchForm={false}
                            partners={partnerWorkshops}
                            showPartnerLink={true}
                            mapHeight={'h-96 md:h-full'}
                />

                <div className={'flex flex-col gap-2 py-16 md:py-32 px-4 md:px-0'}>
                    <h2 className={'text-4xl text-gray-900'}><span
                        className={'font-extrabold'}>{translatePages(
                        'HOME.PARTNER_WORKSHOPS')}</span> {translatePages(
                        'HOME.ALL_OVER_GERMANY')}</h2>
                    <p className={'text-lg'}>
                        {translatePages('HOME.WORKSHOP_CONTENT')}
                    </p>
                    <div className={'my-5'}>
                        <Link href={`${locale}/partners`}>
                            <a>
                        <span className={'text-blue-800'}> {translateCommon(
                            'MORE_ABOUT')} </span>
                            </a>
                        </Link>
                    </div>
                    <div className={'w-10 h-0.5 bg-gray-800 my-5 block'}/>
                    <div className={'grid grid-cols-2 gap-10'}>
                        <div className={'break-words'}>
                            <h3 className={'font-title uppercase text-red-600 text-sm sm:text-base md:text-xl tracking-wide'}>
                        <span className={'font-bold'}>{translatePages(
                            'HOME.PARTNER_WORKSHOPS')} </span> {translatePages(
                                'HOME.ALL_OVER_GERMANY')}</h3>

                        </div>
                        <div className={'break-words'}>
                            <h3 className={'font-title uppercase text-red-600 text-sm sm:text-base md:text-xl tracking-wide'}>
                                {/*<span className={'font-bold'}> {translatePages(*/}
                                {/*    'HOME.GUARANTEE')}</span> */}
                                {translatePages(
                                    'HOME.COUNTRIES')}
                            </h3>

                        </div>
                        <AnimatedNumber value={partnerWorkshops.length} from={0}
                                        className={'text-7xl font-bold text-red-600'}/>
                        <AnimatedNumber value={numOfCountries} from={0}
                                        className={'text-7xl font-bold text-red-600'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
