import React from 'react';

const CustomNextArrowButton = ({onClickNext, className = ''}) => {

  return <div onClick={onClickNext}
              className={'bg-gray-200 hover:bg-gray-700 p-2 rounded-full h-8 w-8 hover:text-gray-200 text-gray-800 z-10 transition absolute right-3 top-1/2 cursor-pointer' +
              className}>
    <svg
        className={'w-auto h-auto '}
        fill="none" stroke="currentColor"
        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
            d="M9 5l7 7-7 7"/>
    </svg>
  </div>;

};

export default CustomNextArrowButton;
