/* This example requires Tailwind CSS v2.0+ */

import {useTranslation} from 'next-i18next';
import Image from 'next/image';
import React from 'react';

export default function HomeIncentivesUs() {
  const {t: translatePages} = useTranslation('pages');

  const perks = [
    {
      name: translatePages('HOME.SELLING_POINT_TITLE_1'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/7c32e3cceeb8bb373e883c59123a5de4.png',
      description:
          translatePages('HOME.SELLING_POINT_CONTENT_1'),
    },
    {
      name: translatePages('HOME.INCENTIVES_2.POINT_2_TITLE'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/cc6c1d5493d7e74b78463a1ca5aeda02.png',
      description: translatePages('HOME.INCENTIVES_2.POINT_2_DESCRIPTION'),
    },
    {
      name: translatePages('HOME.SELLING_POINT_TITLE_2'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/26cf5e74a992fe210ef01fd0e3a5acab.png',
      description: translatePages('HOME.SELLING_POINT_CONTENT_2'),
    },
    {
      name: translatePages('HOME.INCENTIVES_2.POINT_4_TITLE'),
      imageSrc: 'https://amptech-spaces.fra1.digitaloceanspaces.com/strapi/7634cc2fe7238770e54ad15d7db4801f.png',
      description: translatePages('HOME.INCENTIVES_2.POINT_4_DESCRIPTION'),
    },
  ];

  return (
      <div className="bg-gray-50">
        <h2 className="sr-only">Our perks</h2>
        <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
          <div
              className="max-w-2xl mx-auto px-4 grid grid-cols-1 gap-y-12 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {perks.map((perk) => (
                <div key={perk.name} className="sm:flex">
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <div className="w-28 h-24 relative">
                        <Image
                            src={perk.imageSrc}
                            className={''}
                            alt={perk.name}
                            layout={'fill'}
                            objectFit={'cover'}
                            loading={'lazy'}
                            quality={100}/>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <h3 className="text-sm font-medium text-gray-900">{perk.name}</h3>
                    <p className="mt-2 text-sm text-gray-500">{perk.description}</p>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
}
