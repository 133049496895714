import {useEffect, useState} from "react";

export const ClientSideComponent = ({
                                        children,
                                        className = "",
                                    }) => {
    const [isClientSide, setIsClientSide] = useState(false);

    useEffect(() => {
        setIsClientSide(true);
    }, []);

    if (!isClientSide) return <></>;

    return <div className={className}>{children}</div>;
};
